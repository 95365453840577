
import axios from '@/utils/http'
import config from '@/config/index'


// export function Login(data) {
//   return axios({
//     url: `${config.baseUrl}/account/login/mobile`,
//     method: 'post',
//     data
//   })
// }
// //登录（账号密码）
// export function loginByAccount({account,password}) {
//   return axios({
//     url: `${config.baseUrl}/account/login/account`,
//     method: 'post',
//     data: {account,password}
//   })
// }
//登录(账号登录/手机号登录)
export function login(url,data){
  return axios({
    url: `${config.baseUrl}/account/login/` + url ,
    method: 'post',
    data
  })
 }
export function getInfo() {
  return axios({
    url: `${config.baseUrl}/account/info`,
    method: 'get'
  })
}
export function logout() {
  return axios({
    url: `${config.baseUrl}/account/logout`,
    method: 'post'
  })
}

export function RegistryByMobile(data) {
  return axios({
    url: `${config.baseUrl}/account/registry/mobile`,
    method: 'post',
    data
  })
}

export function RetrievePassword(data) {
  return axios({
    url: `${config.baseUrl}/account/password/retrieve`,
    method: 'post',
    data
  })
}

/**
 * 激活账号
 * @param {*} data
 * @returns
 */
export function activeAccount(data){
  return axios({
    url:`${config.baseUrl}/account/active`,
    method: 'put',
    data
  })
}

/**
 * 检查账号是否存在
 * @param {string} acct - 要检查的账号
 * @returns {Promise} - 返回一个Promise，包含检查结果
 */
export function checkAccount(acct){
  // 发送GET请求到指定的URL，检查账号是否存在
  return axios({
    // 拼接URL，包括基础URL和检查账号的路径
    url:`${config.baseUrl}/account/check`,
    // 使用GET方法发送请求
    method: 'get',
    // 将账号作为查询参数传递
    params:{acct}
  })  
}
