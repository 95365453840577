import axios from '@/utils/http'
import config from '@/config/index'

// 企业管理--查询分页
export function getcompanyCertifyByPage(params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/getByPage`,
    method: 'get',
    params
  })
}
//企业管理--详情
export function getcompanyCertifyDetail(id, params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/detail/${id}`,
    method: 'get',
    params
  })
}
// 企业管理--热门企业
export function getBoothHot(params) {
  return axios({
    url: `${config.baseUrl}/booth/getHot`,
    method: 'get',
    params
  })
}

// 企业页-详情接口
export function getCompanyIntroduceInfo(id) {
  return axios({
    url: `${config.baseUrl}/companyCertify/detail/${id}`,
    method: 'get'
  })
}

// 提交企业认证
export function companyCertifySubmit(data) {
  return axios({
    url: `${config.baseUrl}/companyCertify/submit`,
    method: 'post',
    data
  })
}

// 提交企业认证
export function confirmCertifyTransferRecord(data) {
  return axios({
    url: `${config.baseUrl}/certify/transfer/confirm`,
    method: 'put',
    data
  })
}

// 认证详情
export function getCompanyCertifyDetail(params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/detail`,
    method: 'GET',
    params
  })
}

// 企业管理--查询分页
export function getCompanyPage(params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/getByPage`,
    method: 'GET',
    params
  })
}
// 企业管理--条件查询
export function getQueryCompany(params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/queryByPage`,
    method: 'GET',
    params
  })
}

// 资质申请（买家/卖家）
export function credentialApply(data) {
  return axios({
    url: `${config.baseUrl}/credentialApply/submit`,
    method: 'post',
    data
  })
}
//获取法人在线认证链接
export function getLegalsignurl(params){
  return axios({
    url: `${config.baseUrl}/qiyue/company/auth/legalsignurl`,
    method: 'get',
    params
  })
}

//企业主推原材料
export function companyPushMainMaterials(companyId){
  return axios({
    url: `${config.baseUrl}/push/main/materials/${companyId}`,
    method: 'get'
  })
}

// 提交认证授权书
export function operauthorizationSubmit(data) {
  return axios({
    url: `${config.baseUrl}/company/submit/operauthorization`,
    method: 'put',
    data
  })
}

export function getCompanyAuthRecord(params){
  return axios({
    url: `${config.baseUrl}/company/auth/record`,
    method: 'get',
    params
  })
}

/**
 * 获取最新一次提交信息
 * @returns 
 */
export function getCompanyAuthInfo(){
  return axios({
    url: `${config.baseUrl}/company/auth/info`,
    method: 'get',
    hideTip: true
  })
}

// 获取所有已经认证公司
export function getCompanyAuthList(params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/getAllCompany`,
    method: 'get',
    params
  })
}

// 获取所有已经认证公司(按首字母分组）
export function getCompanyAuthListByFirstLetter(params) {
  return axios({
    url: `${config.baseUrl}/companyCertify/group/firstLetter`,
    method: 'get',
    params
  })
}

/**
 * 检查公司名称是否已存在
 * @param {string} name - 要检查的公司名称
 * @returns {Promise} - 返回一个Promise，包含检查结果
 */
export function checkCompanyName(name) {
  // 发送GET请求到指定的URL，检查公司名称是否已存在
  return axios({
    // 拼接URL，包括基础URL和检查公司名称的路径
    url: `${config.baseUrl}/companyCertify/check`,
    // 使用GET方法发送请求
    method: 'get',
    // 将公司名称作为查询参数传递
    params: {
      name
    }
  }) 
}
